import { FC } from 'react';
import CellParams from 'types/ui/CellParams';

import './style/TableHeader.css';

export interface Props {
  columns: CellParams[];
}

const TableHeader: FC<Props> = ({ columns }) => {
  return (
    <section className="custom-table-header">
      {columns.map((item, index) => (
        <section
          key={index}
          style={{ width: item.width }}
          className="custom-table-header-cell"
        >
          {item.label}
        </section>
      ))}
    </section>
  );
};

export default TableHeader;
