import TableColumn from './PromotionColumn';

import { CellParams } from 'classes/TableColumn';

import { Promotion } from '../../types/';
import { Icon } from '@blueprintjs/core';

export class TitleColumn extends TableColumn {
  constructor(
    identifier: string,
    params: CellParams,
    private onPromotionSelect: (promotion: Promotion) => void
  ) {
    super(identifier, params);
  }

  render(promotion: Promotion): React.ReactNode {
    return (
      <div
        onClick={() => this.onPromotionSelect(promotion)}
        className="cursor-pointer"
        title={promotion.title}
      >
        <Icon icon="build" className="promotion-manager-text-icon" />
        <span className="promotion-manager-item-title">{promotion.title}</span>
      </div>
    );
  }
}

export default TitleColumn;
