import { useCallback, useEffect, useState } from 'react';
import fetchHistoryLogs, { Params } from 'actions/v3/collectionHistory';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'app/Router/Components/types';
import { HistoryLog, HistoryLogListResponse } from '../types';

type Props = {
  collectionId: number;
};

function useFetchCollectionHistory({ collectionId }: Props) {
  const dispatch = useDispatch();
  const [historyLogs, setHistoryLogs] = useState<Array<HistoryLog>>([]);

  const session = useSelector((state: AppState) => state.session);

  const apiRequest = useCallback(() => {
    const params: Params = {
      sessionToken: session.token,
      offset: 0,
      limit: 100,
      collectionId,
    };

    // @ts-ignore
    return dispatch(fetchHistoryLogs(params)).then(
      (response: HistoryLogListResponse) => {
        setHistoryLogs(response.data.results);
      }
    );
  }, [collectionId, session.token, dispatch]);

  useEffect(() => {
    const params: Params = {
      sessionToken: session.token,
      offset: 0,
      limit: 100,
      collectionId,
    };

    // @ts-ignore
    dispatch(fetchHistoryLogs(params)).then((response) => {
      setHistoryLogs(response.data.results);
    });

    return () => {};
  }, [session.token, collectionId, dispatch]);

  return {
    historyLogs,
    apiRequest,
  };
}

export default useFetchCollectionHistory;
