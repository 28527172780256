import TableColumn from './HistoryLogColumn';

import { CellParams } from 'classes/TableColumn';

import { HistoryLog } from '../../types';
import { Icon } from '@blueprintjs/core';

export class StartedAtColumn extends TableColumn {
  constructor(
    identifier: string,
    params: CellParams,
    private onHistoryLogSelect: (promotion: HistoryLog) => void
  ) {
    super(identifier, params);
  }

  render(historyLog: HistoryLog): React.ReactNode {
    const date = new Date(historyLog.started_at);

    return (
      <div onClick={() => this.onHistoryLogSelect(historyLog)}>
        <Icon icon="history" className="promotion-manager-text-icon" />
        <span>{date.toLocaleString()}</span>
      </div>
    );
  }
}

export default StartedAtColumn;
