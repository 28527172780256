import axios from 'axios';

import asyncActionCreator from '../../asyncActionCreator';

import { Promotion } from 'components/Promotions/PromotionManager/types';

import { PromotionDeleteAction } from 'db/routes';

export type Params = {
  sessionToken: string;
  promotion: Promotion;
  collectionId: number;
};

const requestDeletePromotion = async (params: Params) => {
  const url = PromotionDeleteAction.URI.replace(
    '{id}',
    params.promotion.id.toString()
  );

  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Token ${params.sessionToken}`,
    },
  };

  try {
    const response = await axios.delete(url, config);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(
        // @ts-ignore
        error.response.message || 'An error occurred during the request'
      );
    } else {
      throw new Error('An unknown error occurred during the request');
    }
  }
};

const deletePromotionAction = (params: Params) => async () => {
  await requestDeletePromotion(params);

  return {
    id: params.promotion.id,
    collectionId: params.collectionId,
  };
};

export const deletePromotion = asyncActionCreator(deletePromotionAction, {
  name: 'DELETE_PROMOTION',
});

export default deletePromotion;
