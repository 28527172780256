import { CellParams } from 'classes/TableColumn';

import {
  TitleColumn,
  PromotedByColumn,
  StatusColumn,
  CreatedByColumn,
} from '../components/Columns';

import PromotionColumn from '../components/Columns/PromotionColumn';
import { ColumnIdentifier } from '../db/column-identfier';
import { Promotion } from '../types';

export function createTableColumn(
  identifer: ColumnIdentifier,
  params: CellParams,
  onPromotionSelect: (promotion: Promotion) => void
): PromotionColumn {
  switch (identifer) {
    case 'title':
      return new TitleColumn(identifer, params, onPromotionSelect);
    case 'promotedby':
      return new PromotedByColumn(identifer, params);
    case 'accepted':
      return new StatusColumn(identifer, params);
    case 'createdby':
      return new CreatedByColumn(identifer, params);
    default:
      throw new Error(`Unknown column label: ${identifer}`);
  }
}
