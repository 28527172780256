import React from 'react';

export type CellParams = {
  width: string;
};

abstract class TableColumn {
  identifier: string;
  params: CellParams;

  constructor(identifier: string, params: CellParams) {
    this.identifier = identifier;
    this.params = params;
  }

  abstract render(content: any): React.ReactNode;
}

export default TableColumn;
