import axios from 'axios';

import asyncActionCreator from '../../asyncActionCreator';

import {
  Promotion,
  Status,
} from 'components/Promotions/PromotionManager/types';

import { PromotionApprovalAccept, PromotionApprovalReject } from 'db/routes';

import { PromotionApprovalResponse } from 'components/Promotions/PromotionManager/types';

export type Params = {
  sessionToken: string;
  promotion: Promotion;
  status: Status;
  collectionId: number;
};

const requestApprovalPromotion = async (params: Params) => {
  const URI =
    params.status === Status.Approved
      ? PromotionApprovalAccept.URI
      : PromotionApprovalReject.URI;

  const url = `${URI}/${params.promotion.id}`;

  const status = params.status === Status.Approved ? 'approved' : 'declined';

  const data = {
    status,
    id: params.promotion.id,
  };

  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Token ${params.sessionToken}`,
    },
  };

  try {
    const response = await axios.post(url, data, config);

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(
        // @ts-ignore
        error.response.message || 'An error occurred during the request'
      );
    } else {
      throw new Error('An unknown error occurred during the request');
    }
  }
};

const approvalPromotionAction = (params: Params) => async () => {
  const response: PromotionApprovalResponse =
    await requestApprovalPromotion(params);

  return {
    id: params.promotion.id,
    collectionId: params.collectionId,
    promotion: response.promotion,
  };
};

export const approvalPromotion = asyncActionCreator(approvalPromotionAction, {
  name: 'APPROVAL_PROMOTION',
});

export default approvalPromotion;
