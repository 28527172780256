import React from 'react';
import { MaybeElement, Icon } from '@blueprintjs/core';
import { BlueprintIcons_16Id } from '@blueprintjs/icons/lib/esm/generated-icons/16px/blueprint-icons-16';

interface DetailSectionProps {
  label: string;
  value?: string | number;
  widthClass?: string;
  labelColor?: string;
  //icon?: string;
  icon?: BlueprintIcons_16Id | MaybeElement;
  element?: React.ReactNode;
}

const DetailSection: React.FC<DetailSectionProps> = ({
  label,
  value,
  widthClass = 'w-1/3',
  labelColor = '#5f6b7c',
  icon,
  element,
}) => (
  <section className="mt-4 flex">
    <span
      className={`font-bold mr-1 ${widthClass} block`}
      style={{ color: labelColor }}
    >
      {label}:
    </span>
    <span className="flex items-center w-2/3">
      <Icon className="mr-2" icon={icon} />
      {value}
      {element}
    </span>
  </section>
);

export default DetailSection;
