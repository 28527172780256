import React from 'react';

import { Promotion } from '../../types';

interface TableCellProps {
  width: string;
  column: { render: (promotion: any) => React.ReactNode };
  promotion: Promotion;
}

const TableCell: React.FC<TableCellProps> = ({ width, column, promotion }) => {
  return (
    <section style={{ width }} className="promotion-manager-custom-table-cell">
      {column.render(promotion)}
    </section>
  );
};

export default TableCell;
