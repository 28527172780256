import TableColumn from './HistoryLogColumn';

import { CellParams } from 'classes/TableColumn';

import { HistoryLog } from '../../types';
import { Icon } from '@blueprintjs/core';

export class ScheduleColumn extends TableColumn {
  constructor(
    identifier: string,
    params: CellParams,
    private onHistoryLogSelect: (promotion: HistoryLog) => void
  ) {
    super(identifier, params);
  }

  render(historyLog: HistoryLog): React.ReactNode {
    return (
      <div
        onClick={() => this.onHistoryLogSelect(historyLog)}
        className="cursor-pointer"
      >
        <Icon icon="timeline-events" className="promotion-manager-text-icon" />
        <span>{historyLog.schedule}</span>
      </div>
    );
  }
}

export default ScheduleColumn;
