'use client';

import { FC, ReactNode } from 'react';

import './styles/FormLabelError.css';

export interface Props {
  children: ReactNode;
}

const FormLabelError: FC<Props> = ({ children }) => {
  return <span className="form-label-error">{children}</span>;
};

export default FormLabelError;
