import { FC, useState } from 'react';

import { Button } from '@blueprintjs/core';

import { Entity } from '@alephdata/followthemoney';

import PromotionDrawer from './Drawer';

import { GraphLayout } from '../../layout';

import './styles/Promotion.css';

import useEntityData from 'hooks/entities/useEntityData';

export interface Props {
  entities: Array<Entity>;
  layout: GraphLayout;
}

const Promotion: FC<Props> = ({ entities, layout }) => {
  const [drawerContentIsOpen, setDrawerIsVisible] = useState<boolean>(false);
  const { entitySetId } = useEntityData();

  const onClickListener = () => {
    setDrawerIsVisible(true);
  };

  return (
    <div className="promotion-container">
      <h5 className="EntityList__category__title">Promotions</h5>
      <section className="promotion-section">
        <Button className="promotion-submit-button" onClick={onClickListener}>
          <span className="promotion-submit-text">Create a new promotion</span>
        </Button>
      </section>

      {entitySetId.length > 0 && (
        <PromotionDrawer
          icon="add"
          isOpen={drawerContentIsOpen}
          title="Promotion"
          setVisiblity={setDrawerIsVisible}
          entities={entities}
          layout={layout}
          entitySetId={entitySetId}
        />
      )}
    </div>
  );
};

export default Promotion;
