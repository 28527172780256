import axios from 'axios';

import asyncActionCreator from '../asyncActionCreator';

import URICreator, { URICreatorParams } from 'classes/URICreator';

import { FetchCollectionHistory } from 'db/routes';

export type Params = {
  sessionToken: string;
  offset?: number;
  limit?: number;
  collectionId: number;
};

const requestFetchHistoryLogs = async (params: Params) => {
  const offset = params.offset ? params.offset : 0;
  const limit = params.limit ? params.limit : 50;

  const uriParams: Record<string, URICreatorParams> = {
    offset: {
      value: offset,
      encode: false,
    },
    limit: {
      value: limit,
      encode: false,
    },
  };

  const url = URICreator.generateURI(
    FetchCollectionHistory.URI.replace('{id}', params.collectionId.toString()),
    uriParams
  );

  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Token ${params.sessionToken}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(
        // @ts-ignore
        error.response.message || 'An error occurred during the request'
      );
    } else {
      throw new Error('An unknown error occurred during the request');
    }
  }
};

const fetchHistoryLogAction = (params: Params) => async () => {
  const data = await requestFetchHistoryLogs(params);

  return { id: params.collectionId, data: data };
};

export const fetchHistoryLogs = asyncActionCreator(fetchHistoryLogAction, {
  name: 'FETCH_COLLECTION_HISTORY_LOG',
});

export default fetchHistoryLogs;
