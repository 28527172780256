const viewIds = {
  OVERVIEW: 'overview',
  DOCUMENTS: 'documents',
  ENTITIES: 'entities',
  XREF: 'xref',
  DIAGRAMS: 'diagrams',
  MAPPINGS: 'mappings',
  MENTIONS: 'mentions',
  SEARCH: 'search',
  GRAPH: 'graph',
  LISTS: 'lists',
  TIMELINES: 'timelines',
  PROMOTIONS: 'promotions',
  HISTORY: 'history',
};

export default viewIds;
