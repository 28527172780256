import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { AppState } from 'app/Router/Components/types';

import fetchEntitySetsEntities from '../api/fetchEntitySetEntities';
import { Entity } from 'react-ftm';

import {
  Entity as FollowTheMoneyEntity,
  Model,
} from '@alephdata/followthemoney';

import useModel from 'hooks/redux/useModel';

type Props = {
  diagramId: string;
};

export type EntityData = {
  entities: Entity[];
  followTheMoneyEntities: FollowTheMoneyEntity[];
};

function useEntitySetEntities({ diagramId }: Props) {
  const [entityData, setEntityData] = useState<EntityData | null>(null);
  const session = useSelector((state: AppState) => state.session);
  const model: Model = useModel();

  useEffect(() => {
    let isMounted = true;

    fetchEntitySetsEntities({ diagramId, sessionToken: session.token }).then(
      (response) => {
        if (isMounted) {
          const entities = response.results;

          const followTheMoneyEntities = response.results.map(
            (record: never) => {
              return new FollowTheMoneyEntity(model, record);
            }
          );

          const data: EntityData = {
            entities,
            followTheMoneyEntities,
          };
          setEntityData(data);
        }
      }
    );

    return () => {
      isMounted = false;
    };
  }, [session.token, diagramId, model]);

  return {
    entityData,
    model,
  };
}

export default useEntitySetEntities;
