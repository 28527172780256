import { FC } from 'react';

import { Status } from 'components/Promotions/PromotionManager/types';
import DetailSection from 'components/Drawer/DetailSection';

export interface Props {
  status: Status;
}

const StatusInfo: FC<Props> = ({ status }) => {
  if (status === Status.Approved)
    return <DetailSection label="Status" value={status} icon='tick' />;
  else
    return <DetailSection label="Status" value={status} icon='cross' />;
};

export default StatusInfo;
