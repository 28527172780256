import React from 'react';
import { ColumnIdentifier } from '../../db/column-identfier';
import { createTableColumn } from '../../factories/createTableColumn';
import TableCell from '../TableCell';

import { Promotion } from '../../types';

interface TableRowProps {
  promotion: Promotion;
  columnIdentifiers: { [key: string]: any };
  rowConfigurations: { [key: string]: { width: string } };
  handlePromotionSelect: (promotion: any) => void;
}

const TableRow: React.FC<TableRowProps> = ({
  promotion,
  columnIdentifiers,
  rowConfigurations,
  handlePromotionSelect,
}) => {
  return (
    <section className="promotion-manager-custom-table-row">
      {Object.keys(columnIdentifiers).map((key, colIndex) => {
        const identifierKey = key as ColumnIdentifier;
        const identifier = key as keyof typeof rowConfigurations;
        const param = rowConfigurations[identifier];
        const column = createTableColumn(
          identifierKey,
          param,
          handlePromotionSelect
        );

        return (
          <TableCell
            key={colIndex}
            width={param.width}
            column={column}
            promotion={promotion}
          />
        );
      })}
    </section>
  );
};

export default TableRow;
