import { PromotionDatasets } from 'db/routes';

export const fetchPromotionDatasets = async (sessionToken: string) => {
  const response = await fetch(PromotionDatasets.URI, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Token ${sessionToken}`,
    },
  });

  if (!response.ok) {
    // Pass the response object to handle in the form component
    throw response;
  }

  return await response.json();
};

export default fetchPromotionDatasets;
