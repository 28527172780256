import { useState, useEffect } from 'react';
import { GraphConfig, GraphLayout, Viewport, EntityManager } from 'react-ftm';

import useFetchEntitySet from 'components/Promotions/PromotionManager/hooks/useFetchEntitySet';
import useEntitySetEntities from 'components/Promotions/PromotionManager/hooks/useEntitySetEntities';
import {
  Edge,
  IGraphLayoutData,
  Vertex,
} from 'react-ftm/components/NetworkDiagram/layout';
import { Namespace } from '@alephdata/followthemoney';

const config = new GraphConfig({
  editorTheme: 'light',
});

type EntitySet = {
  layout: IGraphLayoutData;
};

const useGraphOptions = (diagramId: string) => {
  const { entityData, model } = useEntitySetEntities({
    diagramId,
  });
  const { entitySet, loading, error } = useFetchEntitySet(diagramId);

  const [manager, setManager] = useState<EntityManager | null>(null);
  const [layout, setLayout] = useState<GraphLayout | null>(null);
  const [viewport, setViewport] = useState<Viewport | null>(null);

  useEffect(() => {
    const isInvalidState =
      loading || error || entitySet === null || entityData === null;

    if (isInvalidState) {
      return;
    }

    const configManager = {
      model: model,
      namespace: new Namespace(diagramId),
      entities: [],
    };
    const manager = new EntityManager(configManager);
    manager.addEntities(entityData.followTheMoneyEntities);

    const layoutData = (entitySet as EntitySet).layout;

    const layout = new GraphLayout(config);

    layout.updateVertices(layoutData);
    layout.updateEdges(layoutData);

    if (layoutData.edges) {
      layoutData.edges.forEach((edge: Edge) => {
        layout.selectElement(edge, {
          additional: true,
          forceVal: true,
        });
      });
    }

    if (layoutData.vertices) {
      layoutData.vertices.forEach((vertex: Vertex) => {
        layout.selectElement(vertex, {
          additional: true,
          forceVal: true,
        });
      });
    }

    layout.updateGroupings(layoutData);
    layout.updateSettings(layoutData.settings);

    layout.layout(manager.getEntities());

    const vertices = layout.getVertices();
    layout.selectElement(vertices, {});

    const viewport = new Viewport(config, 0.2);

    const initialBounds = layout.getVisibleVertexRect();
    viewport.fitToRect(initialBounds);

    setManager(manager);
    setLayout(layout);
    setViewport(viewport);
  }, [loading, error, entitySet, entityData, model, diagramId]);

  return {
    manager,
    layout,
    viewport,
    loading,
    error,
    entitySet,
    entityData,
    setViewport,
  };
};

export default useGraphOptions;
