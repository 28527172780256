'use client';

import { FC } from 'react';

import { NetworkDiagram, GraphConfig, Viewport } from 'react-ftm';

import useGraphOptions from './hooks/useGraphOptions';

import './styles/GraphTab.css';

export interface Props {
  diagramId: string;
}

const config = new GraphConfig({
  editorTheme: 'light',
});

const GraphTab: FC<Props> = ({ diagramId }) => {
  const {
    manager,
    layout,
    viewport,
    loading,
    error,
    entitySet,
    entityData,
    setViewport,
  } = useGraphOptions(diagramId);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading entity set</div>;
  if (entitySet === null) return <div>The entitySet is empty</div>;
  if (entityData === null) return <div>entities are an empty</div>;

  const updateLayout = () => {};
  const updateViewport = (viewport: Viewport, { animate = false } = {}) => {
    if (layout) {
      setViewport(viewport);
    }
  };

  return (
    <section className="drawer-full-screen-section">
      <div className="DiagramEditorGraphTab">
        {manager && layout && viewport && (
          <NetworkDiagram
            config={config}
            entityManager={manager}
            layout={layout}
            viewport={viewport}
            updateLayout={updateLayout}
            updateViewport={updateViewport}
            externalFilterText={'text'}
            writeable={false}
            locale={'en'}
            showSideBar={false}
            showToolbar={false}
            diagramClassName="GraphTabNetworkDiagram"
          />
        )}
      </div>
    </section>
  );
};

export default GraphTab;
