import Joi from 'joi';

const schema = Joi.object({
  label: Joi.string().trim().min(3).required().messages({
    'string.empty': 'Title is not allowed to be empty',
  }),
  category: Joi.string().required().messages({
    'string.empty': 'Category is not allowed to be empty',
  }),
  summary: Joi.string().allow('').optional(),
  casefile: Joi.boolean().required(),
  languages: Joi.array().items(Joi.string()).optional(),
  remote_source: Joi.string().required().messages({
    'string.empty': 'Source is not allowed to be empty',
  }),
  remote_schedule: Joi.string().required().messages({
    'string.base': 'Schedule is not allowed to be empty',
    'string.empty': 'Schedule is not allowed to be empty',
    'string.required': 'Schedule is not allowed to be empty',
  }),
});

export default schema;
