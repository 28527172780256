import { useCallback, useEffect, useState } from 'react';
import {
  type Promotion,
  DrawerSharedProps,
} from 'components/Promotions/PromotionManager/types';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'app/Router/Components/types';

import deletePromotion, {
  Params,
} from 'actions/v3/promotionActions/deletePromotion';

type Props = {
  promotion: Promotion;
  drawer: DrawerSharedProps;
  collectionId: number;
};

function useDeletePromotion({ promotion, drawer, collectionId }: Props) {
  const dispatch = useDispatch();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [confirmationText, setConfirmationText] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [popError, setPopError] = useState<string | null>(null);

  const session = useSelector((state: AppState) => state.session);

  const handleConfirmDelete = useCallback(async () => {
    setPopError(null);

    const params: Params = {
      sessionToken: session.token,
      promotion,
      collectionId,
    };

    try {
      // @ts-ignore
      dispatch(deletePromotion(params)).then(() => {
        setIsDialogOpen(false);
        drawer.handleClosePromotionDrawer();
      });
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setPopError('An unknown error occurred during deletion');
      }
    }
  }, [session.token, promotion, collectionId, dispatch, drawer]);

  const handleDeleteClick = () => {
    setIsDialogOpen(true);
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleConfirmDelete();
      } else if (e.key === 'Escape') {
        setIsDialogOpen(false);
      }
    };

    if (isDialogOpen) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleConfirmDelete, isDialogOpen]);

  return {
    isDialogOpen,
    setIsDialogOpen,
    setConfirmationText,
    confirmationText,
    handleConfirmDelete,
    handleDeleteClick,
    error,
    popError,
    setPopError,
  };
}

export default useDeletePromotion;
