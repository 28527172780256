import React, { Component } from 'react';
import { Button, Classes, Intent } from '@blueprintjs/core';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import c from 'classnames';

import withRouter from 'app/withRouter';
import { createCollectionV3, updateCollectionPermissions } from 'actions';
import { selectMetadata, selectSession } from 'selectors';

import { showWarningToast } from 'app/toast';
import { Language, Role } from 'components/common';
import FormDialog from 'dialogs/common/FormDialog';
import getCollectionLink from 'util/getCollectionLink';

import schema from './schema';
import RequestErrors from 'components/ErrorHandler/RequestErrors';
import ResponseErrors from 'components/ErrorHandler/ResponseErrors';

const messages = defineMessages({
  label_placeholder: {
    id: 'case.label_placeholder',
    defaultMessage: 'Untitled dataset',
  },
  language_placeholder: {
    id: 'case.language_placeholder',
    defaultMessage: 'Select languages',
  },
  summary_placeholder: {
    id: 'case.summary',
    defaultMessage: 'A brief description of the dataset',
  },
  save: {
    id: 'case.save',
    defaultMessage: 'Save',
  },
  share_with: {
    id: 'case.users',
    defaultMessage: 'Search users',
  },
  title: {
    id: 'case.title',
    defaultMessage: 'Create A Remote Dataset',
  },
});

/* eslint-disable */

class CreatedatasetDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collection: {
        label: '',
        summary: '',
        category: '',
        casefile: false,
        languages: [],
        remote_source: '',
        remote_schedule: '',
      },
      permissions: [],
      blocking: false,
      errors: {
        request: null,
        response: null,
      },
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeLabel = this.onChangeLabel.bind(this);
    this.onChangeSummary = this.onChangeSummary.bind(this);
    this.onAddRole = this.onAddRole.bind(this);
    this.onDeleteRole = this.onDeleteRole.bind(this);
    this.onSelectLanguages = this.onSelectLanguages.bind(this);

    this.onFieldChange = this.onFieldChange.bind(this);
  }

  resetErrors() {
    this.setState({
      errors: {
        request: null,
        response: null,
      },
    });
  }

  onAddRole(role) {
    const { permissions } = this.state;
    permissions.push({ role, read: true, write: true });
    this.setState({ permissions });
  }

  onDeleteRole(role) {
    const { permissions } = this.state;
    const newPermissions = permissions.filter(
      (permission) => permission.role.id !== role.role.id
    );
    this.setState({ permissions: newPermissions });
  }

  onSelectLanguages(languages) {
    const { collection } = this.state;
    collection.languages = languages;
    this.setState({ collection });
  }

  onFieldChange({ target }) {
    const { collection } = this.state;
    collection[target.id] = target.value !== '' ? target.value : null;
    this.setState({ collection, changed: true });
  }

  async onSubmit() {
    const {
      navigate,
      createCollectionV3,
      toggleDialog,
      updateCollectionPermissions,
      preventRedirect,
    } = this.props;

    const { collection, permissions } = this.state;
    if (!this.checkValid()) return;
    this.setState({ blocking: true });

    this.resetErrors();

    try {
      this.setState();
      const { error } = schema.validate(collection, { abortEarly: false });

      if (error) {
        this.setState({
          blocking: false,
          errors: {
            request: error.details,
            response: null,
          },
        });

        showWarningToast('Please fix the errors below');

        return;
      }

      const response = await createCollectionV3(
        collection,
        this.props.session.token
      );

      const collectionId = response.data.id;
      await updateCollectionPermissions(collectionId, permissions);
      this.setState({ blocking: false });

      if (preventRedirect) {
        toggleDialog(response.data);
      } else {
        navigate(getCollectionLink({ collection: response.data }));
      }
    } catch (axiosResponse) {
      const responseErrors = axiosResponse.response.data?.errors;

      showWarningToast('Please fix the errors below');

      this.setState({
        blocking: false,
        errors: {
          response: responseErrors,
        },
      });

      showWarningToast(e.message);
    }
  }

  onChangeLabel({ target }) {
    const { collection } = this.state;

    collection.label = target.value;
    this.setState({ collection });
  }

  onChangeSummary({ target }) {
    const { collection } = this.state;
    collection.summary = target.value;
    this.setState({ collection });
  }

  checkValid() {
    const { collection } = this.state;
    return collection.label.trim().length >= 3;
  }

  render() {
    const { intl, isOpen, toggleDialog } = this.props;
    const { collection, permissions, blocking } = this.state;
    const exclude = permissions.map((perm) => parseInt(perm.role.id, 10));
    const disabled = blocking || !this.checkValid();

    let categories = []
    for (let k in this.props.metadata.categories)
      if (k !== 'casefile')
        categories.push([k, this.props.metadata.categories[k]])
    categories.sort((a, b) => a[1].localeCompare(b[1]))

    return (
      <FormDialog
        processing={blocking}
        icon="briefcase"
        className="CreatedatasetDialog"
        isOpen={isOpen}
        title={intl.formatMessage(messages.title)}
        onClose={toggleDialog}
        enforceFocus={false}
        autoFocus={false}
      >
        <div className={Classes.DIALOG_BODY}>
          <div className={Classes.FORM_GROUP}>
            <label className={Classes.LABEL} htmlFor="label">
              <FormattedMessage id="case.choose.name" defaultMessage="Title" />
              <div className={c(Classes.INPUT_GROUP, Classes.FILL)}>
                <input
                  id="label"
                  type="text"
                  className={Classes.INPUT}
                  autoComplete="off"
                  placeholder={intl.formatMessage(messages.label_placeholder)}
                  onChange={this.onChangeLabel}
                  value={collection.label}
                />
              </div>
            </label>
          </div>
          <div className={Classes.FORM_GROUP}>
            <label className={Classes.LABEL}>
              <FormattedMessage
                id="collection.edit.info.category"
                defaultMessage="Category"
              />
            </label>
            <div className={c(Classes.SELECT, Classes.FILL)}>
              <select
                id="category"
                onChange={this.onFieldChange}
                value={collection.category}
              >
                <option value="">Please choose a category</option>
                {categories.map((c) => (
                  <option key={c[0]} value={c[0]}>{c[1]}</option>
                ))}
              </select>
            </div>
          </div>
          <div className={Classes.FORM_GROUP}>
            <label className={Classes.LABEL} htmlFor="summary">
              <FormattedMessage
                id="case.choose.summary"
                defaultMessage="Summary"
              />
              <div className={c(Classes.INPUT_GROUP, Classes.FILL)}>
                <textarea
                  id="summary"
                  className={Classes.INPUT}
                  placeholder={intl.formatMessage(messages.summary_placeholder)}
                  onChange={this.onChangeSummary}
                  value={collection.summary}
                />
              </div>
            </label>
          </div>
          <div className={Classes.FORM_GROUP}>
            <label className={Classes.LABEL}>
              <FormattedMessage
                id="case.chose.languages"
                defaultMessage="Languages"
              />
            </label>
            <Language.MultiSelect
              onSubmit={this.onSelectLanguages}
              values={collection.languages || []}
              inputProps={{
                inputRef: null,
                placeholder: intl.formatMessage(messages.language_placeholder),
              }}
            />
            <div className={Classes.FORM_HELPER_TEXT}>
              <FormattedMessage
                id="case.languages.helper"
                defaultMessage="Used for optical text recognition in non-Latin alphabets."
              />
            </div>
          </div>
          <div className={Classes.FORM_GROUP}>
            <label className={Classes.LABEL}>
              <FormattedMessage
                id="case.share.with"
                defaultMessage="Share with"
              />
              <Role.Select onSelect={this.onAddRole} exclude={exclude} />
            </label>
          </div>
          {permissions.length !== 0 && (
            <table className="settings-table">
              <tbody>
                {permissions.map((permission) => (
                  <tr key={permission.role.id + 1}>
                    <td>
                      <Role.Label role={permission.role} icon={false} long />
                    </td>
                    <td>
                      <Button
                        onClick={(e) => this.onDeleteRole(permission, e)}
                        small
                        minimal
                        icon="remove"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <div className={Classes.FORM_GROUP}>
            <label className={Classes.LABEL} htmlFor="remote_source">
              <FormattedMessage
                id="case.choose.remote_source"
                defaultMessage="Remote Dataset Source"
              />
              <div className={c(Classes.INPUT_GROUP, Classes.FILL)}>
                <input
                  id="remote_source"
                  type="text"
                  className={Classes.INPUT}
                  autoComplete="off"
                  placeholder={'gs:// or https://'}
                  onChange={this.onFieldChange}
                  value={collection.remote_source}
                />
              </div>
            </label>
          </div>

          <div className="mt-3">
            <>
              <label className={Classes.LABEL}>
                <FormattedMessage
                  id="collection.edit.info.remote_schedule"
                  defaultMessage="Remote dataset schedule (cron format, UTC)"
                />
              </label>
              <input
                id="remote_schedule"
                type="text"
                className={c(Classes.INPUT, Classes.FILL)}
                placeholder={'@midnight, 0 0 * * * *'}
                onChange={this.onFieldChange}
                value={collection.remote_schedule || ''}
              />
            </>
          </div>
        </div>
        <div>
          {this.state.errors.request !== null && (
            <RequestErrors errors={this.state.errors.request} />
          )}
          {this.state.errors.response !== null && (
            <ResponseErrors errors={this.state.errors.response} />
          )}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={this.onSubmit}
              intent={Intent.PRIMARY}
              disabled={disabled}
              text={intl.formatMessage(messages.save)}
            />
          </div>
        </div>
      </FormDialog>
    );
  }
}

const mapStateToProps = (state) => ({
  metadata: selectMetadata(state),
  session: selectSession(state),
});

CreatedatasetDialog = injectIntl(CreatedatasetDialog);
CreatedatasetDialog = withRouter(CreatedatasetDialog);
export default connect(mapStateToProps, {
  createCollectionV3,
  updateCollectionPermissions,
})(CreatedatasetDialog);
