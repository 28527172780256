import { FC } from 'react';

import PromotionManager from 'components/Promotions/PromotionManager/dataset';

type Props = {
  collectionId: number;
};

const PromotionsTab: FC<Props> = ({ collectionId }) => {
  return <PromotionManager collectionId={collectionId} />;
};

export default PromotionsTab;
