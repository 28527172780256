import FormLabelError from 'components/FormLabelError';
import { FC } from 'react';

type ErrorsProps = {
  errors: {
    [key: string]: string[];
  };
};

const Errors: FC<ErrorsProps> = ({ errors }) => {
  if (
    !errors ||
    typeof errors !== 'object' ||
    Object.keys(errors).length === 0
  ) {
    return null;
  }

  const errorMessages = Object.entries(errors).flatMap(([field, messages]) =>
    messages.map((message) => `${field}: ${message}`)
  );

  return (
    <ul className="mx-4">
      {errorMessages.map((message, index) => (
        <FormLabelError key={index}>{message}</FormLabelError>
      ))}
    </ul>
  );
};

export default Errors;
