const columnIdentifiers = {
  title: 'title',
  promotedby: 'promotedby',
  accepted: 'accepted',
  createdby: 'createdby',
} as const;

export default columnIdentifiers;

export type ColumnIdentifier = keyof typeof columnIdentifiers;
