'use client';

import { FC } from 'react';

import TableHeader from 'components/Table/TableHeader';

import PromotionManagerDrawer from './components/Drawer';

import headerColumns from './db/header-columns';
import rowConfigurations from './db/row-configurations';
import columnIdentifiers from './db/column-identfier';

import usePromotionList from './hooks/usePromotionList';
import usePromotionDrawer from './hooks/usePromotionDrawer';

import TableRow from './components/TableRow';

import './styles/PromotionManager.css';

type Props = {
  collectionId: number;
};

const Manager: FC<Props> = ({ collectionId }) => {
  const { promotions } = usePromotionList({
    collectionId,
  });

  const basePath = 'datasets';

  const {
    selectedPromotion,
    handlePromotionSelect,
    handleClosePromotionDrawer,
    getShareLink,
  } = usePromotionDrawer(promotions, { basePath });

  return (
    <>
      <div className="promotion-manager">
        <TableHeader columns={headerColumns} />

        {promotions.map((promotion, rowIndex) => (
          <TableRow
            key={rowIndex}
            promotion={promotion}
            columnIdentifiers={columnIdentifiers}
            rowConfigurations={rowConfigurations}
            handlePromotionSelect={handlePromotionSelect}
          />
        ))}

        {selectedPromotion && (
          <PromotionManagerDrawer
            promotion={selectedPromotion}
            isOpen={!!selectedPromotion}
            handleClosePromotionDrawer={handleClosePromotionDrawer}
            collectionId={collectionId}
            getShareLink={getShareLink}
          />
        )}
      </div>
    </>
  );
};

export default Manager;
