import { createReducer } from 'redux-act';

import { fetchExports } from 'actions';
import { loadState, loadStart, loadError, loadComplete } from 'reducers/util';

export type ExportsState = {};

const initialState = loadState();

export default createReducer(
  {
    [fetchExports.START]: (state) => loadStart(state),
    [fetchExports.ERROR]: (state, { error }) => loadError(state, error),
    [fetchExports.COMPLETE]: (state, { exports }) => loadComplete(exports),
  },
  initialState
);
