import CellParams from 'types/ui/CellParams';

const params: CellParams[] = [
  {
    label: 'Source',
    width: '45%',
  },
  {
    label: 'Schedule',
    width: '20%',
  },
  {
    label: 'Started at',
    width: '17.5%',
  },
  {
    label: 'Finished at',
    width: '17.5%',
  },
];

export default params;
