import Joi from 'joi';

import { FieldValues } from 'react-hook-form';

export type Schema = {
  title: string;
  description: string;
  datasetId: string;
  entitySetId: string;
} & FieldValues;

export function loadSchema(entitySetId: string): Joi.ObjectSchema<Schema> {
  const schema = Joi.object({
    title: Joi.string().required().messages({
      'string.empty': 'Title is not allowed to be empty',
    }),
    description: Joi.string().optional().allow(''),
    dataset_id: Joi.number().required().messages({
      'string.empty': 'Dataset is a required field',
      'number.base': 'Dataset is a required field',
    }),
    entityset_id: Joi.string()
      .default(entitySetId)
      .allow(entitySetId)
      .messages({
        'string.empty': 'Entity set data is required',
      }),
  });

  return schema;
}
