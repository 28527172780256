import axios from 'axios';

import asyncActionCreator from '../../asyncActionCreator';

import { PromotionCreate } from 'db/routes';

type Request = {
  collection_id: number;
  dataset_id: number;
  description: string;
  kind: string;
  match_rules: string;
  title: string;
};

export type Params = {
  sessionToken: string;
  requestData: Request;
};

const requestCreateDatasetPromotion = async (params: Params) => {
  const url = PromotionCreate.URI;

  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Token ${params.sessionToken}`,
    },
  };

  try {
    const response = await axios.post(url, params.requestData, config);

    return {
      id: params.requestData.collection_id,
      promotion: response.data.promotion,
    };
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw error.response.data;
    } else {
      throw new Error('An unknown error occurred during the request');
    }
  }
};

const createDatasetPromotionAction = (params: Params) => async () => {
  const response = await requestCreateDatasetPromotion(params);

  return {
    id: response.id,
    promotion: response.promotion,
  };
};

export const createDatasetPromotion = asyncActionCreator(
  createDatasetPromotionAction,
  {
    name: 'CREATE_DATASET_PROMOTION',
  }
);

export default createDatasetPromotion;
