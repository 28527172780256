import { FC } from 'react';

import { Link } from 'react-router-dom';

import { Promotion } from 'components/Promotions/PromotionManager/types';
import DetailSection from 'components/Drawer/DetailSection';

export interface Props {
  promotion: Promotion;
}

const PromotedFrom: FC<Props> = ({ promotion }) => {
  let url, kind, lbl;
  if (promotion.kind === 'dataset') {
    url = `/datasets/${promotion.dataset_id}`;
    kind = 'Dataset';
    lbl = promotion.dataset?.label;
  } else {
    url = `/diagrams/${promotion.entityset_id}`;
    kind = 'Network diagram';
    lbl = promotion.entityset?.label;
  }

  const element = (
    <span>
      {kind}: <Link to={url}>{lbl}</Link>
    </span>
  );

  return <DetailSection label="Promoted from" element={element} icon='many-to-one' />;
};

export default PromotedFrom;
