import React, {
  FC,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from 'react';

import './styles/FormTextAreaField.css';

export interface TextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const FormTextAreaField: FC<TextAreaProps> = forwardRef<
  HTMLTextAreaElement,
  TextAreaProps
>(({ ...rest }, ref) => {
  const localRef = useRef<HTMLTextAreaElement | null>(null);

  useImperativeHandle(ref, () => localRef.current!);

  const adjustHeight = () => {
    const textarea = localRef.current;

    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustHeight();
  }, []);

  return (
    <div className="bp4-input-group bp4-fill">
      <textarea
        ref={localRef}
        {...rest}
        className={`${rest.className || ''} bp4-input form-text-area-field`}
        onInput={adjustHeight} // Adjust height on input
      />
    </div>
  );
});

FormTextAreaField.displayName = 'FormTextAreaField';

export default FormTextAreaField;
