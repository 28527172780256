import { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import type { Promotion } from 'components/Promotions/PromotionManager/types';

interface UsePromotionDrawerConfig {
  basePath: string;
}

function usePromotionDrawer(
  promotions: Promotion[],
  config: UsePromotionDrawerConfig
) {
  const [selectedPromotion, setSelectedPromotion] = useState<Promotion | null>(
    null
  );
  const navigate = useNavigate();
  const location = useLocation();
  const isEffectRun = useRef(false);
  const { promotionId, collectionId } = useParams<{
    promotionId?: string;
    collectionId: string;
  }>();
  const { basePath } = config;

  useEffect(() => {
    if (isEffectRun.current || promotions.length === 0) return;

    if (promotionId) {
      const promotion = promotions.find(
        (promotion) => promotion.id === Number(promotionId)
      );

      if (promotion) {
        setSelectedPromotion(promotion);
      }
    } else {
      setSelectedPromotion(null);
    }

    isEffectRun.current = true;
  }, [promotionId, location.hash, promotions]);

  const handlePromotionSelect = (promotion: Promotion) => {
    navigate(
      `/${basePath}/${collectionId}/promotions/${promotion.id}#mode=promotions`
    );
    setSelectedPromotion(promotion);
  };

  const handleClosePromotionDrawer = () => {
    navigate(`/${basePath}/${collectionId}#mode=promotions`);
    setSelectedPromotion(null);
  };

  const getShareLink = () => {
    if (!selectedPromotion) return '';
    return `${window.location.origin}/${basePath}/${collectionId}/promotions/${selectedPromotion.id}#mode=promotions`;
  };

  return {
    selectedPromotion,
    handlePromotionSelect,
    handleClosePromotionDrawer,
    getShareLink,
  };
}

export default usePromotionDrawer;
