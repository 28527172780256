import React, { Component } from 'react';
import { Button, Classes, Intent, Checkbox, Divider } from '@blueprintjs/core';
import c from 'classnames';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Role, Country, Language } from 'components/common';
import FormDialog from 'dialogs/common/FormDialog';
import { showSuccessToast, showWarningToast } from 'app/toast';
import { updateCollectionV3 } from 'actions';
import { selectMetadata, selectAdmin, selectSession } from 'selectors';

import ResponseErrors from 'components/ErrorHandler/ResponseErrors';

const messages = defineMessages({
  placeholder_label: {
    id: 'collection.edit.info.placeholder_label',
    defaultMessage: 'A label',
  },
  placeholder_summary: {
    id: 'collection.edit.info.placeholder_summary',
    defaultMessage: 'A brief summary',
  },
  placeholder_publisher: {
    id: 'collection.edit.info.placeholder_publisher',
    defaultMessage: 'Organisation or person publishing this data',
  },
  placeholder_publisher_url: {
    id: 'collection.edit.info.placeholder_publisher_url',
    defaultMessage: 'Link to the publisher',
  },
  placeholder_info_url: {
    id: 'collection.edit.info.placeholder_info_url',
    defaultMessage: 'Link to further information',
  },
  placeholder_data_url: {
    id: 'collection.edit.info.placeholder_data_url',
    defaultMessage: 'Link to the raw data in a downloadable form',
  },
  placeholder_country: {
    id: 'collection.edit.info.placeholder_country',
    defaultMessage: 'Select countries',
  },
  placeholder_language: {
    id: 'collection.edit.info.placeholder_language',
    defaultMessage: 'Select languages',
  },
  check_restricted: {
    id: 'collection.edit.info.restricted',
    defaultMessage: 'This dataset is restricted and viewers should be warned.',
  },
  title: {
    id: 'collection.edit.title',
    defaultMessage: 'Settings',
  },
  delete_button: {
    id: 'collection.edit.info.delete',
    defaultMessage: 'Delete',
  },
  analyze_button: {
    id: 'collection.edit.info.analyze',
    defaultMessage: 'Re-process',
  },
  cancel_button: {
    id: 'collection.edit.info.cancel',
    defaultMessage: 'Cancel',
  },
  save_button: {
    id: 'collection.edit.info.save',
    defaultMessage: 'Save changes',
  },
  save_success: {
    id: 'collection.edit.save_success',
    defaultMessage: 'Your changes are saved.',
  },
  promotion_toggle: {
    id: 'collection.promotion_toggle',
    defaultMessage: 'This dataset is in the extended format and supports promotion options',
  },
  remote_dataset_enabled: {
    id: 'collection.remote_dataset_enabled',
    defaultMessage: 'This dataset automatically updates from a remote source',
  },
});

export class CollectionEditDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blocking: false,
      changed: false,
      errors: {
        request: null,
        response: null,
      },
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onToggleRestricted = this.onToggleRestricted.bind(this);
    this.onSelectCountries = this.onSelectCountries.bind(this);
    this.onSelectLanguages = this.onSelectLanguages.bind(this);
    this.onSelectCreator = this.onSelectCreator.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);

    this.onTogglePromotionOption = this.onTogglePromotionOption.bind(this);

    this.isChecked = this.isChecked.bind(this);
  }

  resetErrors() {
    this.setState({
      errors: {
        request: null,
        response: null,
      },
    });
  }

  static getDerivedStateFromProps(props, state) {
    const collection = state.changed
      ? state.collection
      : { ...props.collection };
    return { collection };
  }

  onFieldChange({ target }) {
    const { collection } = this.state;
    collection[target.id] = target.value !== '' ? target.value : null;
    this.setState({ collection, changed: true });
  }

  onToggleRestricted() {
    const { collection } = this.state;
    collection.restricted = !collection.restricted;
    this.setState({ collection, changed: true });
  }

  onTogglePromotionOption() {
    const { collection } = this.state;
    collection.enable_promotion = !collection.enable_promotion;
    this.setState({ collection, changed: true });
  }

  onSelectCountries(countries) {
    const { collection } = this.state;
    collection.countries = countries;
    this.setState({ collection, changed: true });
  }

  onSelectLanguages(languages) {
    const { collection } = this.state;
    collection.languages = languages;
    this.setState({ collection, changed: true });
  }

  onSelectCreator(creator) {
    const { collection } = this.state;
    collection.creator = creator;
    this.setState({ collection, changed: true });
  }

  isChecked(key) {
    const { collection } = this.state;

    const keys = Object.keys(collection);
    if (keys.includes(key)) {
      return collection[key] ? true : false;
    }

    return false;
  }

  async onSubmit() {
    const { intl } = this.props;
    const { collection } = this.state;
    this.setState({ blocking: true });

    this.resetErrors();

    try {
      const token = this.props.session.token;
      await this.props.updateCollectionV3(collection, token);

      showSuccessToast(intl.formatMessage(messages.save_success));
      this.props.toggleDialog();
    } catch (requestResponse) {
      const responseErrors = requestResponse.response.data.errors;

      showWarningToast('Please fix the errors below');

      this.setState({
        blocking: false,
        errors: {
          response: responseErrors,
        },
      });
    }
    this.setState({ blocking: false, changed: false });
  }

  render() {
    const { intl, metadata, isAdmin } = this.props;
    const { collection, blocking } = this.state;
    const { categories, frequencies } = metadata;
    const isCasefile = collection.category === 'casefile';
    const isRemoteEnabled = () => isAdmin && collection.kind === 'remote-dataset';

    return (
      <FormDialog
        processing={blocking}
        icon="cog"
        isOpen={this.props.isOpen}
        onClose={this.props.toggleDialog}
        title={intl.formatMessage(messages.title)}
        enforceFocus={false}
      >
        <div className={Classes.DIALOG_BODY}>
          <div className={Classes.FORM_GROUP}>
            <label className={Classes.LABEL}>
              <FormattedMessage
                id="collection.edit.info.label"
                defaultMessage="Label"
              />
            </label>
            <div className={Classes.FORM_CONTENT}>
              <input
                id="label"
                type="text"
                className={c(Classes.INPUT, Classes.FILL)}
                placeholder={intl.formatMessage(messages.placeholder_label)}
                onChange={this.onFieldChange}
                value={collection.label || ''}
              />
            </div>
          </div>
          {isAdmin && (
            <div className={Classes.FORM_GROUP}>
              <label className={Classes.LABEL}>
                <FormattedMessage
                  id="collection.edit.info.category"
                  defaultMessage="Category"
                />
              </label>
              <div className={c(Classes.SELECT, Classes.FILL)}>
                <select
                  id="category"
                  onChange={this.onFieldChange}
                  value={collection.category}
                >
                  {Object.keys(categories).map((key) => (
                    <option key={key} value={key}>
                      {categories[key]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
          <div className={Classes.FORM_GROUP}>
            <label className={Classes.LABEL}>
              <FormattedMessage
                id="collection.edit.info.summary"
                defaultMessage="Summary"
              />
            </label>
            <div className={Classes.FORM_CONTENT}>
              <textarea
                id="summary"
                className={c(Classes.INPUT, Classes.FILL)}
                placeholder={intl.formatMessage(
                  messages.placeholder_summary
                )}
                rows={5}
                onChange={this.onFieldChange}
                value={collection.summary || ''}
              />
            </div>
          </div>
          {!isCasefile && (
            <>
              <div className={Classes.FORM_GROUP}>
                <label className={Classes.LABEL}>
                  <FormattedMessage
                    id="collection.edit.info.publisher"
                    defaultMessage="Publisher"
                  />
                </label>
                <div className={Classes.FILL}>
                  <input
                    id="publisher"
                    type="text"
                    className={c(Classes.INPUT, Classes.FILL)}
                    placeholder={intl.formatMessage(
                      messages.placeholder_publisher
                    )}
                    onChange={this.onFieldChange}
                    value={collection.publisher || ''}
                  />
                </div>
              </div>
              <div className={Classes.FORM_GROUP}>
                <label className={Classes.LABEL}>
                  <FormattedMessage
                    id="collection.edit.info.publisher_url"
                    defaultMessage="Publisher URL"
                  />
                </label>
                <div className={Classes.FILL}>
                  <input
                    id="publisher_url"
                    type="text"
                    className={c(Classes.INPUT, Classes.FILL)}
                    placeholder={intl.formatMessage(
                      messages.placeholder_publisher_url
                    )}
                    onChange={this.onFieldChange}
                    value={collection.publisher_url || ''}
                  />
                </div>
              </div>
              <div className={Classes.FORM_GROUP}>
                <label className={Classes.LABEL}>
                  <FormattedMessage
                    id="collection.edit.info.info_url"
                    defaultMessage="Information URL"
                  />
                </label>
                <div className={Classes.FILL}>
                  <input
                    id="info_url"
                    type="text"
                    className={c(Classes.INPUT, Classes.FILL)}
                    placeholder={intl.formatMessage(
                      messages.placeholder_info_url
                    )}
                    onChange={this.onFieldChange}
                    value={collection.info_url || ''}
                  />
                </div>
              </div>
              <div className={Classes.FORM_GROUP}>
                <label className={Classes.LABEL}>
                  <FormattedMessage
                    id="collection.edit.info.data_url"
                    defaultMessage="Data source URL"
                  />
                </label>
                <div className={Classes.FILL}>
                  <input
                    id="data_url"
                    type="text"
                    className={c(Classes.INPUT, Classes.FILL)}
                    placeholder={intl.formatMessage(
                      messages.placeholder_data_url
                    )}
                    onChange={this.onFieldChange}
                    value={collection.data_url || ''}
                  />
                </div>
              </div>
              <div className={Classes.FORM_GROUP}>
                <label className={Classes.LABEL}>
                  <FormattedMessage
                    id="collection.edit.info.frequency"
                    defaultMessage="Update frequency"
                  />
                </label>
                <div className={c(Classes.SELECT, Classes.FILL)}>
                  <select
                    id="frequency"
                    onChange={this.onFieldChange}
                    value={collection.frequency || ''}
                  >
                    {Object.keys(frequencies).map((key) => (
                      <option key={key} value={key || ''}>
                        {frequencies[key]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </>
          )}
          {isAdmin && (
            <div className={Classes.FORM_GROUP}>
              <label className={Classes.LABEL}>
                <FormattedMessage
                  id="collection.edit.info.creator"
                  defaultMessage="Manager"
                />
              </label>
              <div className={Classes.FORM_CONTENT}>
                <Role.Select
                  role={collection.creator}
                  onSelect={this.onSelectCreator}
                />
              </div>
            </div>
          )}
          <div className={Classes.FORM_GROUP}>
            <label className={Classes.LABEL}>
              <FormattedMessage
                id="collection.edit.info.countries"
                defaultMessage="Countries"
              />
            </label>
            <Country.MultiSelect
              onSubmit={this.onSelectCountries}
              values={collection.countries || []}
              inputProps={{
                inputRef: null,
                placeholder: intl.formatMessage(
                  messages.placeholder_country
                ),
              }}
            />
          </div>
          <div className={Classes.FORM_GROUP}>
            <label className={Classes.LABEL}>
              <FormattedMessage
                id="collection.edit.info.languages"
                defaultMessage="Languages"
              />
            </label>
            <Language.MultiSelect
              onSubmit={this.onSelectLanguages}
              values={collection.languages || []}
              inputProps={{
                inputRef: null,
                placeholder: intl.formatMessage(
                  messages.placeholder_language
                ),
              }}
            />
            <div className={Classes.FORM_HELPER_TEXT}>
              <FormattedMessage
                id="case.languages.helper"
                defaultMessage="Used for optical text recognition in non-Latin alphabets."
              />
            </div>
          </div>
          {!isCasefile && (
            <>
              <div className={Classes.FORM_GROUP}>
                <label className={Classes.LABEL}>
                  <FormattedMessage
                    id="collection.edit.info.foreign_id"
                    defaultMessage="Foreign ID"
                  />
                </label>
                <div className={Classes.FORM_CONTENT}>
                  <input
                    className={c(Classes.INPUT, Classes.FILL)}
                    type="text"
                    disabled
                    value={collection.foreign_id || ''}
                  />
                </div>
              </div>
              <div className={Classes.FORM_GROUP}>
                <div className={Classes.FILL}>
                  <Checkbox
                    checked={this.isChecked('restricted')}
                    label={intl.formatMessage(messages.check_restricted)}
                    onChange={this.onToggleRestricted}
                  />
                </div>
                {!isRemoteEnabled() && (
                  <div className={Classes.FILL}>
                    <Checkbox
                      checked={this.isChecked('enable_promotion')}
                      label={intl.formatMessage(messages.promotion_toggle)}
                      onChange={this.onTogglePromotionOption}
                    />
                  </div>
                )}
                {isRemoteEnabled() && (
                  <>
                    <Divider />
                    <span className="font-bold">
                      Remote Dataset Settings
                    </span>
                    <div className={Classes.FILL}>
                      <Checkbox
                        disabled={true}
                        checked={true}
                        label={intl.formatMessage(
                          messages.remote_dataset_enabled
                        )}
                      />
                    </div>
                    <div className="mt-3">
                      <>
                        <label className={Classes.LABEL}>
                          <FormattedMessage
                            id="collection.edit.info.remote_dataset_source"
                            defaultMessage="Remote dataset source"
                          />
                        </label>
                        <input
                          id="remote_source"
                          disabled={!isAdmin}
                          type="text"
                          className={c(Classes.INPUT, Classes.FILL)}
                          placeholder={'Remote dataset source'}
                          onChange={this.onFieldChange}
                          value={collection.remote_source || ''}
                        />
                      </>
                    </div>
                    <div className="mt-3">
                      <>
                        <label className={Classes.LABEL}>
                          <FormattedMessage
                            id="collection.edit.info.remote_dataset_schedule"
                            defaultMessage="Remote dataset schedule (cron format, UTC)"
                          />
                        </label>
                        <input
                          id="remote_schedule"
                          disabled={!isAdmin}
                          type="text"
                          className={c(Classes.INPUT, Classes.FILL)}
                          placeholder={'@midnight, 0 0 * * * *'}
                          onChange={this.onFieldChange}
                          value={collection.remote_schedule || ''}
                        />
                      </>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
        <div>
          {this.state.errors.response !== null && (
            <ResponseErrors errors={this.state.errors.response} />
          )}
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={() => this.onSubmit()}
              intent={Intent.PRIMARY}
              disabled={blocking}
              text={intl.formatMessage(messages.save_button)}
            />
          </div>
        </div>
      </FormDialog>
    );
  }
}

const mapStateToProps = (state) => ({
  metadata: selectMetadata(state),
  isAdmin: selectAdmin(state),
  session: selectSession(state),
});

export default compose(
  connect(mapStateToProps, {
    updateCollectionV3,
  }),
  injectIntl
)(CollectionEditDialog);
