import { FC, forwardRef } from 'react';

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {}

const FormInputField: FC<Props> = forwardRef<HTMLInputElement, Props>(
  ({ ...rest }, ref) => {
    const className = rest.className
      ? `${rest.className} bp4-input`
      : 'bp4-input';

    return (
      <div className="bp4-input-group bp4-fill">
        <input ref={ref} {...rest} className={className} />
      </div>
    );
  }
);

FormInputField.displayName = 'FormInputField';

export default FormInputField;
