'use client';

import { FC } from 'react';

import { Routes, Route } from 'react-router-dom';

import { routes as legacyRedirects } from 'app/legacyRedirects';

import NotFoundScreen from 'screens/NotFoundScreen/NotFoundScreen';

import routes from './routes';

const DefaultRoutes = () => {
  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
      {legacyRedirects}
      <Route path="*" element={<NotFoundScreen />} />
    </Routes>
  );
};

const AppRoutes: FC = () => {
  return <DefaultRoutes />;
};

export default AppRoutes;
