import { FC, useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import {MaybeElement, Drawer, Button, OverlayToaster, Position} from '@blueprintjs/core';
import { BlueprintIcons_16Id } from '@blueprintjs/icons/lib/esm/generated-icons/16px/blueprint-icons-16';

import { loadErrorIntent } from 'components/OverlayToasterPopUp';

import FormLabelError from 'components/FormLabelError';

import Promotion from 'types/db/Promotion';

import FormInputField from 'components/FormInputField';
import FormTextAreaField from 'components/FormTextAreaField';

import { AppState } from 'app/Router/Components/types';

import { loadSchema } from './schema';

import './styles/PromotionDrawer.css';
import executeSubmitListener from './utils/executeSubmitListener';

import type { Props as PromotionProps } from './index';
import { ErrorType } from './types';
import RequestErrotType from 'types/requests/ErrorType';
import useDatasetsList from './hooks/useDatasetsList';

export type Props = {
  isOpen: boolean;
  title: string;
  icon: BlueprintIcons_16Id | MaybeElement;
  setVisiblity: React.Dispatch<React.SetStateAction<boolean>>;
  entitySetId: string;
} & PromotionProps;

const PromotionDrawer: FC<Props> = ({
  isOpen,
  title,
  icon,
  setVisiblity,
  entities,
  layout,
  entitySetId,
}) => {
  const [errorType, setErrorType] = useState<
    ErrorType | RequestErrotType | null
  >(null);
  const [, setCreatedPromotion] = useState<Promotion | null>(null);

  const { datasets, selectedDatasetOption, setSelectedDatasetOption } =
    useDatasetsList();

  const closeListener = () => {
    setVisiblity(false);
  };

  layout.toJSONSelectedGroup(entities);

  const toasterRef = useRef<OverlayToaster | null>(null);

  const session = useSelector((state: AppState) => state.session);

  const schema = loadSchema(entitySetId);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: joiResolver(schema),
  });

  const submitListener = async (data: any) => {
    const graphLayoutData = layout.toJSONSelectedGroup(entities);

    executeSubmitListener({
      fieldData: data,
      token: session.token,
      ref: toasterRef,
      setCreatedPromotion,
      setErrorType,
      setVisiblity,
      rollbackFieldValues,
      graphLayoutData,
    });
  };

  const rollbackFieldValues = () => {
    reset();
    setSelectedDatasetOption('');
  };

  useEffect(() => {
    if (errorType) {
      loadErrorIntent({
        message: errorType,
        ref: toasterRef,
      });

      setErrorType(null);
    }
  }, [errorType]);

  return (
    <>
      <OverlayToaster position={Position.TOP} ref={toasterRef} />
      <Drawer
        isOpen={isOpen}
        canOutsideClickClose={true}
        icon={icon}
        title={title}
        onClose={closeListener}
      >
        <form onSubmit={handleSubmit(submitListener)}>
          <div className="promotion-drawer-container">
            <section className="promotion-drawer-section">
              <label
                htmlFor="promotion-drawer-dataset"
                className="font-bold mb-2"
              >
                Dataset
              </label>
              <select
                id="promotion-drawer-dataset"
                className="promotion-selectbox border-solid border border-black"
                value={selectedDatasetOption}
                {...register('dataset_id')}
                onChange={(e) => setSelectedDatasetOption(e.target.value)}
              >
                <option value="">Select dataset</option>
                {datasets.map((dataset) => (
                  <option
                    value={dataset.collection_id}
                    key={dataset.collection_id}
                  >
                    {dataset.label}
                  </option>
                ))}
              </select>
              {errors.dataset_id && (
                <FormLabelError>{errors.dataset_id.message}</FormLabelError>
              )}
            </section>
            <div className="promotion-drawer-section mt-3">
              <label
                className="font-bold"
                htmlFor="promotion-drawer-title mb-2"
              >
                Title
              </label>
              <FormInputField
                {...register('title')}
                placeholder="Untitled promotion"
                className="promotion-drawer-title"
                id="promotion-drawer-title"
              />
              {errors.title && (
                <FormLabelError>{errors.title.message}</FormLabelError>
              )}
            </div>
            <div className="promotion-drawer-section mt-3">
              <label
                htmlFor="promotion-drawer-description"
                className="font-bold mb-1"
              >
                Description
              </label>
              <FormTextAreaField
                id="promotion-drawer-description"
                placeholder="A brief description of the promotion"
                {...register('description')}
              />
            </div>
            <div className="promotion-drawer-section mt-3">
              <Button className="promotion-drawer-submit-button" type="submit">
                Create a new promotion
              </Button>
            </div>
          </div>
        </form>
      </Drawer>
    </>
  );
};

export default PromotionDrawer;
