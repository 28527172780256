type Identifiers = {
  container: string;
  tabGraph: string;
  tabDatasets: string;
};

const testingIdentifiers: Identifiers = {
  container: 'navbar-container',
  tabGraph: 'navbar-tab-graph',
  tabDatasets: 'navbar-tab-datasets',
};

export { testingIdentifiers };
