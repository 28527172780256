import FormLabelError from 'components/FormLabelError';
import { FC } from 'react';

type ErrorDetail = {
  message: string;
};

type ErrorsProps = {
  errors: ErrorDetail[];
};

const Errors: FC<ErrorsProps> = ({ errors }) => {
  if (!Array.isArray(errors) || errors.length === 0) {
    return null;
  }

  return (
    <>
      <ul className="mx-4">
        {errors.map((error, index) => (
          <FormLabelError key={index}>{error.message}</FormLabelError>
        ))}
      </ul>
    </>
  );
};

export default Errors;
