import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DrawerState } from './types';

const useEntityData = () => {
  const entitySets = useSelector((state: DrawerState) => state.entitySets);

  const [entityData, setEntityData] = useState({
    entitySetId: '',
    collectionId: -1,
  });

  useEffect(() => {
    const entitySetId = Object.keys(entitySets).at(0);

    if (!entitySetId) {
      setEntityData({ entitySetId: '', collectionId: -1 });
      return;
    }

    const collection = entitySets[entitySetId].collection;

    setEntityData({
      entitySetId: entitySetId as string,
      collectionId: parseInt(collection.collection_id) as number,
    });
  }, [entitySets]);

  return entityData;
};

export default useEntityData;
