import { CellParams } from 'classes/TableColumn';

type Props = {
  title: CellParams;
  promotedby: CellParams;
  accepted: CellParams;
  createdby: CellParams;
};

const params: Props = {
  title: {
    width: '25%',
  },
  promotedby: {
    width: '25%',
  },
  accepted: {
    width: '25%',
  },
  createdby: {
    width: '25%',
  },
};

export default params;
