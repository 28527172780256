import { OverlayToaster } from '@blueprintjs/core';

import { IntentSUCCESS } from 'components/OverlayToasterPopUp';

import Promotion from 'types/db/Promotion';
import ErrorType from 'types/requests/ErrorType';

import createPromotion from '../services/useCreatePromotion';
import { IGraphLayoutData } from 'react-ftm/components/NetworkDiagram/layout';

type Props = {
  fieldData: any;
  token: string;
  ref: React.MutableRefObject<OverlayToaster | null>;
  setCreatedPromotion: React.Dispatch<React.SetStateAction<Promotion | null>>;
  setErrorType: React.Dispatch<React.SetStateAction<ErrorType | null>>;
  setVisiblity: React.Dispatch<React.SetStateAction<boolean>>;
  rollbackFieldValues: () => void;
  graphLayoutData: IGraphLayoutData;
};

type Payload = {
  title: string;
  collection_id: number;
  entityset_id: string;
  description: string;
  kind: string;
  layout: IGraphLayoutData;
};

async function executeSubmitListener({ fieldData, token, ref, setCreatedPromotion, setErrorType, setVisiblity, rollbackFieldValues, graphLayoutData }: Props) {
  setCreatedPromotion(null);

  try {
    const payload: Payload = {
      title: fieldData.title,
      collection_id: fieldData.dataset_id,
      entityset_id: fieldData.entityset_id,
      description: fieldData.description,
      layout: graphLayoutData,
      kind: 'diagram',
    };

    const response = await createPromotion(payload, token);
    handleSuccess(response.promotion as Promotion, ref, setCreatedPromotion, setVisiblity);
    rollbackFieldValues();
  } catch (error) {
    handleError(error, setErrorType);
  }
}

function handleSuccess(promotion: Promotion, ref: React.MutableRefObject<OverlayToaster | null>, setCreatedPromotion: React.Dispatch<React.SetStateAction<Promotion | null>>, setVisiblity: React.Dispatch<React.SetStateAction<boolean>>) {
  setCreatedPromotion(promotion);
  setVisiblity(false);

  IntentSUCCESS({
    ref,
    message: `${promotion.title} saved successfully!`,
  });
}

function handleError(error: unknown, setErrorType: React.Dispatch<React.SetStateAction<ErrorType | null>>) {
  if (error instanceof Response) {
    switch (error.status) {
      case 400:
        setErrorType('BadRequest');
        break;
      case 409:
        setErrorType('Conflict');
        break;
      case 500:
        setErrorType('ServerError');
        break;
      default:
        setErrorType('UnexpectedError');
    }
  } else {
    setErrorType('NetworkError');
  }
}

export default executeSubmitListener;
