import { RouteProps } from 'react-router-dom';

import OAuthScreen from 'screens/OAuthScreen/OAuthScreen';
import LogoutScreen from 'screens/LogoutScreen/LogoutScreen';
import ActivateScreen from 'screens/ActivateScreen/ActivateScreen';
import HomeScreen from 'screens/HomeScreen/HomeScreen';
import SearchScreen from 'screens/SearchScreen/SearchScreen';
import NotificationsScreen from 'screens/NotificationsScreen/NotificationsScreen';
import PagesScreen from 'screens/PagesScreen/PagesScreen';
import AlertsScreen from 'screens/AlertsScreen/AlertsScreen';
import SettingsScreen from 'screens/SettingsScreen/SettingsScreen';
import SystemStatusScreen from 'screens/SystemStatusScreen/SystemStatusScreen';
import GroupScreen from 'screens/GroupScreen/GroupScreen';
import InvestigationIndexScreen from 'screens/InvestigationIndexScreen/InvestigationIndexScreen';
import DatasetIndexScreen from 'screens/DatasetIndexScreen/DatasetIndexScreen';
import CollectionScreen from 'screens/CollectionScreen/CollectionScreen';
import InvestigationScreen from 'screens/InvestigationScreen/InvestigationScreen';
import EntitySetIndexScreen from 'screens/EntitySetIndexScreen/EntitySetIndexScreen';
import DiagramScreen from 'screens/DiagramScreen/DiagramScreen';
import ProfileScreen from 'screens/ProfileScreen/ProfileScreen';
import ListScreen from 'screens/ListScreen/ListScreen';
import TimelineScreen from 'screens/TimelineScreen/TimelineScreen';
import EntitySetScreen from 'screens/EntitySetScreen/EntitySetScreen';
import EntityScreen from 'screens/EntityScreen/EntityScreen';
import ExportsScreen from 'screens/ExportsScreen/ExportsScreen';

const routes: RouteProps[] = [
  {
    path: 'oauth',
    element: <OAuthScreen />,
  },
  {
    path: 'logout',
    element: <LogoutScreen />,
  },
  {
    path: 'pages/:page',
    element: <PagesScreen />,
  },
  {
    path: 'activate/:code',
    element: <ActivateScreen />,
  },
  {
    path: 'entities/:entityId',
    element: <EntityScreen />,
  },
  {
    path: 'datasets',
    element: <DatasetIndexScreen />,
  },
  {
    path: 'investigations',
    element: <InvestigationIndexScreen />,
  },
  {
    path: 'datasets/:collectionId',
    element: <CollectionScreen />,
  },
  {
    path: 'datasets/:collectionId/promotions/:promotionId',
    element: <CollectionScreen />,
  },
  {
    path: 'investigations/:collectionId',
    element: <InvestigationScreen />,
  },
  {
    path: 'investigations/:collectionId/promotions/:promotionId',
    element: <InvestigationScreen />,
  },
  {
    path: 'profiles/:profileId',
    element: <ProfileScreen />,
  },
  {
    path: 'diagrams/:entitySetId',
    element: <DiagramScreen />,
  },
  {
    path: 'diagrams',
    element: <EntitySetIndexScreen />,
  },
  {
    path: 'lists/:entitySetId',
    element: <ListScreen />,
  },
  {
    path: 'lists',
    element: <EntitySetIndexScreen />,
  },
  {
    path: 'timelines/:entitySetId',
    element: <TimelineScreen />,
  },
  {
    path: 'timelines',
    element: <EntitySetIndexScreen />,
  },
  {
    path: 'sets/:entitySetId',
    element: <EntitySetScreen />,
  },
  {
    path: 'search',
    element: <SearchScreen />,
  },
  {
    path: 'notifications',
    element: <NotificationsScreen />,
  },
  {
    path: 'alerts',
    element: <AlertsScreen />,
  },
  {
    path: 'exports',
    element: <ExportsScreen />,
  },
  {
    path: 'settings',
    element: <SettingsScreen />,
  },
  {
    path: 'status',
    element: <SystemStatusScreen />,
  },
  {
    path: 'groups/:groupId',
    element: <GroupScreen />,
  },
  {
    path: '/',
    element: <HomeScreen />,
  },
];

export default routes;
