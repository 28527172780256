import { Component } from 'react';
import { connect } from 'react-redux';

import { fetchMetadata, fetchMessages, dismissMessage } from 'actions';
import {
  selectSession,
  selectMetadata,
  selectMessages,
  selectPinnedMessage,
} from 'selectors';

import { Page } from './Components';
import PageState from './utils/PageState';

import './Router.scss';

const MESSAGES_INTERVAL = 15 * 60 * 1000; // every 15 minutes

class Router extends Component {
  constructor(props) {
    super(props);
    this.state = {pageState: PageState.Loading};
  }

  componentDidMount() {
    this.fetchIfNeeded();
    this.setMessagesInterval();
  }

  componentDidUpdate() {
    this.fetchIfNeeded();
  }

  componentWillUnmount() {
    this.clearMessagesInterval();
  }

  fetchIfNeeded() {
    if (this.props.metadata.shouldLoad && !this.props.metadata.isError) {
      this.props.fetchMetadata()
        .then (() => { this.setState({pageState: PageState.Success}) })
        .catch(() => { this.setState({pageState: PageState.SomethingIsWrong}) })
    }
    if (this.props.messages.shouldLoad & !this.props.messages.isError) {
      this.fetchMessages();
    }
  }

  fetchMessages() {
    const { metadata } = this.props;

    if (metadata?.app?.messages_url) {
      this.props.fetchMessages(metadata.app.messages_url);
    }
  }

  setMessagesInterval() {
    const id = setInterval(() => this.fetchMessages(), MESSAGES_INTERVAL);
    this.setState(() => ({ messagesInterval: id }));
  }

  clearMessagesInterval() {
    if (this.state?.messagesInterval) {
      clearInterval(this.state.messagesInterval);
    }
  }

  render() {
    const { pinnedMessage, dismissMessage } = this.props;

    return (
      <Page
        pageState={this.state.pageState}
        pinnedMessage={pinnedMessage}
        dismissMessage={dismissMessage}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  metadata: selectMetadata(state),
  messages: selectMessages(state),
  pinnedMessage: selectPinnedMessage(state),
  session: selectSession(state),
});

export default connect(mapStateToProps, {
  fetchMetadata,
  fetchMessages,
  dismissMessage,
})(Router);
