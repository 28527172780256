import { useCallback, useEffect } from 'react';

import fetchPromotions, {
  Params,
} from 'actions/v3/promotionActions/fetchPromotions';

import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'app/Router/Components/types';
import { Promotion } from '../types';
import { selectPromotions } from 'selectors';

type Props = {
  collectionId: number;
};

function usePromotionList({ collectionId }: Props) {
  const dispatch = useDispatch();

  const promotions: Array<Promotion> = useSelector((state: any) =>
    selectPromotions(state, collectionId)
  );

  const session = useSelector((state: AppState) => state.session);

  const apiRequest = useCallback(() => {
    const params: Params = {
      sessionToken: session.token,
      offset: 0,
      limit: 100,
      'filter:collection_id': collectionId,
    };

    // TODO:
    // The error is:
    // Argument of type '(dispatch: any) => Promise<any>' is not assignable to parameter of type 'AnyAction'.
    // The solution is updating store configuration
    // @ts-ignore
    dispatch(fetchPromotions(params));
  }, [collectionId, session.token, dispatch]);

  useEffect(() => {
    if (promotions.length === 0) {
      const params: Params = {
        sessionToken: session.token,
        offset: 0,
        limit: 100,
        'filter:collection_id': collectionId,
      };

      // TODO:
      // The error is:
      // Argument of type '(dispatch: any) => Promise<any>' is not assignable to parameter of type 'AnyAction'.
      // The solution is updating store configuration
      // @ts-ignore
      dispatch(fetchPromotions(params));
    }

    return () => {};
  }, [session.token, collectionId, promotions.length, dispatch]);

  return {
    promotions,
    apiRequest,
  };
}

export default usePromotionList;
