import { useState } from 'react';

import { AppState } from 'app/Router/Components/types';
import { Promotion, Status, PromotionApprovalResponse } from '../types';
import { useDispatch, useSelector } from 'react-redux';

import approvalPromotions, {
  Params,
} from 'actions/v3/promotionActions/approvalPromotions';

function useApprovalPromotion(
  promotion: Promotion,
  setUiPromotion: React.Dispatch<React.SetStateAction<Promotion>>,
  collectionId: number
) {
  const dispatch = useDispatch();

  const [actionType, setActionType] = useState<Status>(Status.New);
  const [responsePromotion, setResponsePromotion] = useState<
    Promotion | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [error, setError] = useState(null);

  const session = useSelector((state: AppState) => state.session);

  const executeRequest = () => {
    const params: Params = {
      sessionToken: session.token,
      status: actionType,
      promotion,
      collectionId,
    };

    setLoading(true);
    setError(null);

    // @ts-ignore
    dispatch(approvalPromotions(params)).then(
      (response: PromotionApprovalResponse) => {
        setLoading(false);
        if (response.promotion) {
          setIsDialogOpen(false);
          setResponsePromotion(response.promotion);
          setUiPromotion(response.promotion);
        }
      }
    );
  };

  return {
    isDialogOpen,
    setIsDialogOpen,
    executeRequest,
    responsePromotion,
    actionType,
    setActionType,
    loading,
    error,
  };
}

export default useApprovalPromotion;
