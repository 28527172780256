type Kind = 'dataset' | 'diagram';

export type Entityset = {
  label: string;
};

export type Promotion = {
  id: number;
  collection_id: number;
  entityset_id: string;
  diagram_id: string;
  dataset_id: number;
  creator_id: string;
  kind: Kind;
  title: string;
  description: string;
  created_at: Date;
  updated_at: Date;
  status: Status;
  promoted_by: role;
  status_changed_by?: role;
  entityset?: Entityset;
  dataset?: Collection;
};

export type Collection = {
  id: string; // Always number; Aleph API quirk.
  label: string;
}

type role = {
  id: string;
  label: string;
  name: string;
  type: string;
};

export type PromotionListResponse = {
  id: string;
  data: {
    results: Array<Promotion>;
  };
};

export type PromotionApprovalResponse = {
  promotion: Promotion;
  status: string;
};

export enum Status {
  New = 'new',
  Declined = 'declined',
  Approved = 'approved',
}

export type DrawerSharedProps = {
  handleClosePromotionDrawer: () => void;
};
