import CellParams from 'types/ui/CellParams';

const params: CellParams[] = [
  {
    label: 'Title',
    width: '25%',
  },
  {
    label: 'Promoted by',
    width: '25%',
  },
  {
    label: 'Status',
    width: '25%',
  },
  {
    label: 'Created',
    width: '25%',
  },
];

export default params;
