import TableColumn from './PromotionColumn';

import { Promotion } from '../../types';

class PromotedByColumn extends TableColumn {
  render(promotion: Promotion): React.ReactNode {
    return <span>{promotion.promoted_by.label}</span>;
  }
}

export default PromotedByColumn;
