import { createReducer } from 'redux-act';

import timestamp from 'util/timestamp';

import {
  forceMutate,
  createCollection,
  updateCollection,
  deleteCollection,
  createEntityMapping,
  updateEntityMapping,
  deleteEntityMapping,
  triggerCollectionCancel,
  updateCollectionPermissions,
  createEntity,
  createEntitySetMutate,
  updateEntitySetItemMutate,
  deleteEntity,
  deleteEntitySet,
  updateRole,
  deleteAlert,
  createAlert,
  createBookmark,
  deleteBookmark,
  loginWithToken,
  logout,
} from 'actions';

export type MutationState = {
  global: number;
  [key: string]: number;
};

const initialState = {
  global: timestamp(),
};

function update(state: MutationState, key: string = 'global'): MutationState {
  return {
    ...state,
    [key]: timestamp(),
  };
}

export default createReducer<MutationState>(
  {
    [forceMutate.toString()]: (state) => update(state),
    [loginWithToken.toString()]: (state) => update(state),
    [logout.toString()]: (state) => update(state),
    [createCollection.COMPLETE.toString()]: (state) => update(state),
    [updateCollection.COMPLETE.toString()]: (state) => update(state),
    [deleteCollection.COMPLETE.toString()]: (state) => update(state),
    [createEntityMapping.COMPLETE.toString()]: (state) => update(state),
    [updateEntityMapping.COMPLETE.toString()]: (state) => update(state),
    [deleteEntityMapping.COMPLETE.toString()]: (state) => update(state),
    [triggerCollectionCancel.COMPLETE.toString()]: (state) => update(state),
    [updateCollectionPermissions.COMPLETE.toString()]: (state) => update(state),
    [createEntity.COMPLETE.toString()]: (state) => update(state),
    [createEntitySetMutate.COMPLETE.toString()]: (state) => update(state),
    [updateEntitySetItemMutate.COMPLETE.toString()]: (state) => update(state),
    [deleteEntity.COMPLETE.toString()]: (state) => update(state),
    [deleteEntitySet.COMPLETE.toString()]: (state) => update(state),
    [updateRole.COMPLETE.toString()]: (state) => update(state),
    [createAlert.COMPLETE.toString()]: (state) => update(state),
    [deleteAlert.COMPLETE.toString()]: (state) => update(state),
    [createBookmark.COMPLETE.toString()]: (state) => update(state, 'bookmarks'),
    [deleteBookmark.COMPLETE.toString()]: (state) => update(state, 'bookmarks'),
  },
  initialState
);
