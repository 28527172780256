import { FC, useState } from 'react';

import { Drawer, Tabs, Tab } from '@blueprintjs/core';

import { Status, type Promotion, DrawerSharedProps } from '../../types';

import { PromotionTab, GraphTab, ApprovalTab } from './Tabs';
import DeletePromotion from './Components/DeletePromotion';
export interface Props {
  promotion: Promotion;
  isOpen: boolean;
  handleClosePromotionDrawer: () => void;
  collectionId: number;
  getShareLink: () => string;
}

const PromotionManagerDrawer: FC<Props> = ({
  promotion,
  isOpen,
  handleClosePromotionDrawer,
  collectionId,
  getShareLink,
}) => {
  const [uiPromotion, setUiPromotion] = useState<Promotion>(promotion);

  const sharedProps: DrawerSharedProps = {
    handleClosePromotionDrawer,
  };

  return (
    <>
      <Drawer
        isOpen={isOpen}
        canOutsideClickClose={true}
        icon='build'
        title={'Promotion ' + promotion.title}
        onClose={handleClosePromotionDrawer}
        className="drawer-full-screen"
      >
        <div className="py-4 px-6 drawer-full-screen-container">
          <Tabs
            className="promotion-manager-drawer-tab-container p-2 drawer-full-screen-tabs"
            defaultSelectedTabId="promotion-manager-tab-promotion-id"
          >
            <Tab
              id="promotion-manager-tab-promotion-id"
              title="Promotion"
              icon="inbox"
              className="drawer-full-screen-tab"
              panel={<PromotionTab promotion={uiPromotion} />}
            />
            {uiPromotion.diagram_id && (
              <Tab
                id="promotion-manager-tab-graph-details-id"
                title="Details"
                icon="gantt-chart"
                className="drawer-full-screen-tab"
                panel={<GraphTab diagramId={uiPromotion.diagram_id} />}
              />
            )}
          </Tabs>

          {uiPromotion.status === Status.New && (
            <section className="promotion-buttons">
              <ApprovalTab
                promotion={uiPromotion}
                setUiPromotion={setUiPromotion}
                collectionId={collectionId}
              />

              <DeletePromotion
                promotion={promotion}
                drawer={sharedProps}
                collectionId={collectionId}
              />
            </section>
          )}

        </div>
      </Drawer>
    </>
  );
};

export default PromotionManagerDrawer;
