import { useEffect, useState } from 'react';

import fetchPromotionDatasets from '../api/fetchPromotionDatasets';
import { useSelector } from 'react-redux';
import { AppState } from 'app/Router/Components/types';
import type { DatasetList, Collection, DatasetEntity } from '../types';

function useDatasetsList() {
  const [datasets, setDatasets] = useState<Array<DatasetEntity>>([]);
  const [selectedDatasetOption, setSelectedDatasetOption] = useState('');

  const session = useSelector((state: AppState) => state.session);

  useEffect(() => {
    fetchPromotionDatasets(session.token).then((response: DatasetList) => {
      const datasets = response.results.map((collection: Collection) => {
        return {
          collection_id: parseInt(collection.collection_id),
          label: collection.label,
        };
      });

      setDatasets(datasets);

      if (datasets.length === 1) {
        setSelectedDatasetOption(datasets[0].collection_id.toString());
      }
    });
  }, [session.token]);

  return {
    datasets,
    selectedDatasetOption,
    setSelectedDatasetOption,
  };
}

export default useDatasetsList;
