import { MaybeElement, OverlayToaster, Intent } from '@blueprintjs/core';
import { BlueprintIcons_16Id } from '@blueprintjs/icons/lib/esm/generated-icons/16px/blueprint-icons-16';

type Props = {
  ref: React.MutableRefObject<OverlayToaster | null>;
  message: string;
  timeout?: number;
  icon?: BlueprintIcons_16Id | MaybeElement;
};

const IntentSUCCESS = ({
  ref,
  message,
  timeout = 5000,
  icon = 'tick',
}: Props) => {
  ref.current?.show({
    message,
    intent: Intent.SUCCESS,
    icon,
    timeout,
  });
};

const loadErrorIntent = ({
  ref,
  message,
  timeout = 5000,
  icon = 'warning-sign',
}: Props) => {
  ref.current?.show({
    message,
    intent: Intent.WARNING,
    icon,
    timeout,
  });
};

export { IntentSUCCESS, loadErrorIntent };
