import TableColumn from './HistoryLogColumn';

import { CellParams } from 'classes/TableColumn';

import { HistoryLog } from '../../types';

export class SourceColumn extends TableColumn {
  constructor(
    identifier: string,
    params: CellParams,
    private onHistoryLogSelect: (promotion: HistoryLog) => void
  ) {
    super(identifier, params);
  }

  render(historyLog: HistoryLog): React.ReactNode {
    return (
      <div>
        <span>{historyLog.source}</span>
      </div>
    );
  }
}

export default SourceColumn;
