export type URICreatorParams = {
  value: string | number | boolean;
  encode?: boolean;
};

class URICreator {
  private baseURI: string;

  constructor(baseURI: string) {
    this.baseURI = baseURI;
  }

  public recreateURI(params: Record<string, URICreatorParams>): string {
    const searchParams = new URLSearchParams();

    Object.keys(params).forEach((key) => {
      const { value, encode } = params[key];
      const paramKey = encode ? encodeURIComponent(key) : key;
      const paramValue = encode
        ? encodeURIComponent(String(value))
        : String(value);

      searchParams.set(paramKey, paramValue);
    });

    return `${this.baseURI}?${searchParams.toString()}`;
  }

  public static generateURI(
    baseURI: string,
    params: Record<string, URICreatorParams>
  ): string {
    const uriCreator = new URICreator(baseURI);
    return uriCreator.recreateURI(params);
  }
}

export default URICreator;
