import URICreator from 'classes/URICreator';

type Route = {
  URI: string;
};

export const SearchRoute:             Route = {URI: '/search'};
export const DatasetsRoute:           Route = {URI: '/datasets'};
export const PromotionCreate:         Route = {URI: '/api/2/promotions'};
export const PromotionList:           Route = {URI: '/api/2/promotions'};
export const PromotionApprovalAccept: Route = {URI: '/api/2/promotions'};
export const PromotionApprovalReject: Route = {URI: '/api/2/promotions'};
export const PromotionDeleteAction:   Route = {URI: '/api/2/promotions/{id}'};
export const UpdateCollection:        Route = {URI: `/api/2/collections`};
export const FetchCollection:         Route = {URI: `/api/2/collections`};
export const FetchEntitySetsEntities: Route = {URI: '/api/2/entitysets/:diagram_id/entities'};
export const CreateCollection:        Route = {URI: '/api/2/collections'};
export const FetchCollectionHistory:  Route = {URI: `/api/2/collections/{id}/fetch`};

export const PromotionDatasets: Route = {
  URI: URICreator.generateURI('/api/2/collections', {
    'filter:enable_promotion': {value: true,        encode: false},
    'exclude:category':        {value: 'casefile',  encode: false},
    limit:                     {value: '9999',      encode: false},
    sort:                      {value: 'label:asc', encode: false},
  }),
};
