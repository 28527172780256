import { PromotionCreate } from 'db/routes';

export const createPromotion = async (data: any, sessionToken: string) => {
  const response = await fetch(PromotionCreate.URI, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Token ${sessionToken}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    // Pass the response object to handle in the form component
    throw response;
  }

  return await response.json();
};

export default createPromotion;
