import { compose } from 'redux';
import { connect } from 'react-redux';

import withRouter from 'app/withRouter';
import PromotionManager from 'components/Promotions/PromotionManager/investigations';
import { collectionPromotionsQuery } from 'queries';
import { selectCollection } from 'selectors';

const mapStateToProps = (state, ownProps) => {
  const { collectionId, location } = ownProps;
  const query = collectionPromotionsQuery(location, collectionId);

  return {
    collection: selectCollection(state, collectionId),
    query,
  };
};

export default compose(withRouter, connect(mapStateToProps))(PromotionManager);
