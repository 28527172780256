const columnIdentifiers = {
  source: 'source',
  schedule: 'schedule',
  started_at: 'started_at',
  finished_at: 'finished_at',
} as const;

export default columnIdentifiers;

export type ColumnIdentifier = keyof typeof columnIdentifiers;
