import axios from 'axios';

import asyncActionCreator from '../../asyncActionCreator';

import URICreator, { URICreatorParams } from 'classes/URICreator';

import { PromotionList } from 'db/routes';

export type Params = {
  sessionToken: string;
  offset?: number;
  limit?: number;
  'filter:collection_id'?: number;
};

const requestFetchPromotions = async (params: Params) => {
  let uriParams = {};
  const offset = params.offset ? params.offset : 0;
  const limit = params.limit ? params.limit : 50;

  const shared: Record<string, URICreatorParams> = {
    offset: {
      value: offset,
      encode: false,
    },
    limit: {
      value: limit,
      encode: false,
    },
  };

  if (params['filter:collection_id']) {
    uriParams = {
      'filter:collection_id': {
        value: params['filter:collection_id'],
        encode: false,
      },
      ...shared,
    };
  } else {
    uriParams = shared;
  }

  const url = URICreator.generateURI(PromotionList.URI, uriParams);

  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Token ${params.sessionToken}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(
        // @ts-ignore
        error.response.message || 'An error occurred during the request'
      );
    } else {
      throw new Error('An unknown error occurred during the request');
    }
  }
};

const fetchPromotionsAction = (params: Params) => async () => {
  const data = await requestFetchPromotions(params);

  return { id: params['filter:collection_id'], data: data };
};

export const fetchPromotions = asyncActionCreator(fetchPromotionsAction, {
  name: 'FETCH_PROMOTIONS',
});

export default fetchPromotions;
