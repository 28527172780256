import { CellParams } from 'classes/TableColumn';

import {
  FinishedAtColumn,
  SourceColumn,
  StartedAtColumn,
  ScheduleColumn,
} from '../components/Columns';

import HistoryLogColumn from '../components/Columns/HistoryLogColumn';
import { ColumnIdentifier } from '../db/column-identfier';
import { HistoryLog } from '../types';

type Props = {
  identifier: ColumnIdentifier;
  params: CellParams;
  onHistoryLogSelect: (historyLog: HistoryLog) => void;
};

export function createTableColumn({
  identifier,
  params,
  onHistoryLogSelect,
}: Props): HistoryLogColumn {
  switch (identifier) {
    case 'source':
      return new SourceColumn(identifier, params, onHistoryLogSelect);
    case 'schedule':
      return new ScheduleColumn(identifier, params, onHistoryLogSelect);
    case 'started_at':
      return new StartedAtColumn(identifier, params, onHistoryLogSelect);
    case 'finished_at':
      return new FinishedAtColumn(identifier, params, onHistoryLogSelect);
    default:
      throw new Error(`Unknown column label: ${identifier}`);
  }
}
