import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchEntitySet } from 'actions/entitySetActions';
import { AppDispatch } from './types';

const useFetchEntitySet = (id: string) => {
  const dispatch = useDispatch<AppDispatch>();
  const [entitySet, setEntitySet] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const action = await dispatch(fetchEntitySet({ id }));

        if (action.data) {
          setEntitySet(action.data);
        } else {
          setError(action.error);
        }
      } catch (err: any) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchData();
    }
  }, [dispatch, id]);

  return { entitySet, loading, error };
};

export default useFetchEntitySet;
