import axios from 'axios';

import asyncActionCreator from '../asyncActionCreator';

import { UpdateCollection, FetchCollection, CreateCollection } from 'db/routes';

export const updateCollectionV3 = asyncActionCreator(
  (collection, sessionToken) => async () => {
    const config = {
      params: { sync: true },
      headers: {
        Authorization: `Token ${sessionToken}`,
      },
    };
    const response = await axios.post(
      `${UpdateCollection.URI}/${collection.id}`,
      collection,
      config
    );
    return { id: collection.id, data: response.data };
  },
  { name: 'UPDATE_COLLECTION' }
);

export const fetchCollectionV3 = asyncActionCreator(
  ({ id, refresh, sessionToken }) =>
    async () => {
      const url = `${FetchCollection.URI}/${id}`;

      const config = {
        params: { refresh },
        headers: {
          Authorization: `Token ${sessionToken}`,
        },
      };

      const response = await axios.get(url, config);

      return { id, data: response.data };
    },
  { name: 'FETCH_COLLECTION' }
);

export const createCollectionV3 = asyncActionCreator(
  (collection, sessionToken) => async () => {
    const config = {
      params: { sync: true },
      headers: {
        Authorization: `Token ${sessionToken}`,
      },
    };

    const response = await axios.post(CreateCollection.URI, collection, config);

    return { id: response.id, data: response.data };
  },
  {
    name: 'CREATE_COLLECTION',
  }
);
