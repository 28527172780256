import { Suspense } from 'react';

import Navbar from 'components/Navbar/Navbar';
import MessageBanner from 'components/MessageBanner/MessageBanner';

import AppRoutes from './AppRoutes';

import Loading from './Loading';

function HappyPath({ pinnedMessage, dismissMessage }) {
  return (
    <>
      <Navbar />
      <MessageBanner message={pinnedMessage} onDismiss={dismissMessage} />
      <Suspense fallback={Loading}>
        <AppRoutes />
      </Suspense>
    </>
  );
}

export default HappyPath;
