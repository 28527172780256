import { applyMiddleware, createStore, Store } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { throttle } from 'lodash';

import rootReducer, { RootState } from 'reducers';
import { loadState, saveState } from './storage';
import errorToastMiddleware from './error-toast-middleware';

const persistedState = loadState();

const store: Store<RootState> = createStore(
  rootReducer,
  persistedState,
  applyMiddleware(thunk as ThunkMiddleware, errorToastMiddleware)
);

export type AppDispatch = typeof store.dispatch;

store.subscribe(
  throttle(() => {
    const { session, config, messages, localBookmarks } = store.getState();

    saveState({
      session,
      config,

      // TODO: Remove after deadline
      // See https://github.com/alephdata/aleph/issues/2864
      bookmarks: localBookmarks,

      // Do not persist the actual messages, only the dismissed message IDs.
      messages: { ...messages, messages: [] },
    });
  })
);

export default store;
