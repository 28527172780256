import React, { Component } from 'react';
import { compose } from 'redux';
import { Button, Card, Classes, Dialog } from '@blueprintjs/core';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

import withRouter from 'app/withRouter';

import './DiagramExportDialog.scss';

const messages = defineMessages({
  title: {
    id: 'diagram.export.title',
    defaultMessage: 'Export options',
  },
  embed_error: {
    id: 'diagram.embed.error',
    defaultMessage: 'Error generating diagram embed',
  },
});

class DiagramExportDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      embedUrl: null,
    };
  }

  render() {
    const {
      entitySet,
      exportFtmWithoutLayout,
      exportSvg,
      intl,
      isOpen,
      toggleDialog,
    } = this.props;

    return (
      <Dialog
        icon="export"
        isOpen={isOpen}
        title={intl.formatMessage(messages.title, { title: entitySet.label })}
        onClose={toggleDialog}
        className="DiagramExportDialog"
      >
        <div className={Classes.DIALOG_BODY}>
          <Card className="DiagramExportDialog__section">
            <Button
              icon="image"
              onClick={() => {
                exportSvg();
                toggleDialog();
              }}
            >
              <FormattedMessage
                id="diagram.export.svg"
                defaultMessage="Export as SVG"
              />
            </Button>
            <p className={Classes.TEXT_MUTED}>
              <FormattedMessage
                id="diagram.export.svg.description"
                defaultMessage="Download a vector graphic with the contents of the diagram."
              />
            </p>
          </Card>

          <Card className="DiagramExportDialog__section">
            <Button
              icon="offline"
              onClick={() => {
                exportFtmWithoutLayout();
                toggleDialog();
              }}
            >
              <FormattedMessage
                id="diagram.export.ftm"
                defaultMessage="Export as .ftm"
              />
            </Button>
            <p className={Classes.TEXT_MUTED}>
              <FormattedMessage
                id="diagram.export.ftm.description"
                defaultMessage="Download the diagram as a data file that can be used in {link} or another Aleph site."
                values={{
                  link: (
                    <a
                      href="https://docs.alephdata.org/guide/aleph-data-desktop"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FormattedMessage
                        id="diagram.export.ftm.link"
                        defaultMessage="Aleph Data Desktop"
                      />
                    </a>
                  ),
                }}
              />
            </p>
          </Card>
        </div>
      </Dialog>
    );
  }
}

export default compose(withRouter, injectIntl)(DiagramExportDialog);
