import { combineReducers } from 'redux';

import messages, { MessagesState } from './messages';
import metadata, { MetadataState } from './metadata';
import mutation, { MutationState } from './mutation';
import session, { SessionState } from './session';
import config, { ConfigState } from './config';
import statistics, { StatisticsState } from './statistics';
import entities, { EntitiesState } from './entities';
import entityTags, { EntityTagsState } from './entityTags';
import values, { ValuesState } from './values';
import collections, { CollectionsState } from './collections';
import collectionPermissions, {
  CollectionPermissionsState,
} from './collectionPermissions';
import entityMappings, { EntityMappingsState } from './entityMappings';
import promotions, { PromotionsState } from './promotions';
import results, { ResultsState } from './results';
import entitySets, { EntitySetsState } from './entitySets';
import entitySetItems, { EntitySetItemsState } from './entitySetItems';
import roles, { RolesState } from './roles';
import notifications, { NotificationsState } from './notifications';
import systemStatus, { SystemStatusState } from './systemStatus';
import exports, { ExportsState } from './exports';
import bookmarks, { BookmarksState } from './bookmarks';
import localBookmarks, { LocalBookmarksState } from './localBookmarks';

const rootReducer = combineReducers({
  messages,
  metadata,
  mutation,
  session,
  config,
  statistics,
  entities,
  entityTags,
  values,
  roles,
  notifications,
  collections,
  collectionPermissions,
  entitySets,
  entitySetItems,
  entityMappings,
  results,
  systemStatus,
  exports,
  bookmarks,
  promotions,

  // TODO: Remove after deadline
  // See https://github.com/alephdata/aleph/issues/2864
  localBookmarks,
});

export type RootState = {
  messages: MessagesState;
  metadata: MetadataState;
  mutation: MutationState;
  session: SessionState;
  config: ConfigState;
  statistics: StatisticsState;
  entities: EntitiesState;
  entityTags: EntityTagsState;
  values: ValuesState;
  collections: CollectionsState;
  collectionPermissions: CollectionPermissionsState;
  entityMappings: EntityMappingsState;
  results: ResultsState;
  entitySets: EntitySetsState;
  entitySetItems: EntitySetItemsState;
  promotions: PromotionsState;
  roles: RolesState;
  notifications: NotificationsState;
  systemStatus: SystemStatusState;
  exports: ExportsState;
  bookmarks: BookmarksState;
  localBookmarks: LocalBookmarksState;
};

export default rootReducer;
