import URICreator, { URICreatorParams } from 'classes/URICreator';

import { FetchEntitySetsEntities } from 'db/routes';

export type Params = {
  sessionToken: string;
  diagramId: string;
  limit?: number;
};

export const fetchEntitySetsEntities = async (params: Params) => {
  const limit = params.limit ? params.limit : 100;

  const uriParams: Record<string, URICreatorParams> = {
    limit: {
      value: limit,
      encode: false,
    },
  };

  const url = URICreator.generateURI(FetchEntitySetsEntities.URI.replace(':diagram_id', params.diagramId), uriParams);

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Token ${params.sessionToken}`,
    },
  });

  if (!response.ok) {
    // Pass the response object to handle in the form component
    throw response;
  }

  return await response.json();
};

export default fetchEntitySetsEntities;
