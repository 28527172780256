import { FC } from 'react';

import DetailSection from 'components/Drawer/DetailSection';

import {
  Promotion,
  Status,
} from 'components/Promotions/PromotionManager/types';

export interface Props {
  promotion: Promotion;
}

const StatusChangedBy: FC<Props> = ({ promotion }) => {
  if (promotion.status !== Status.Approved) return null;
  if (!promotion.status_changed_by) return null;

  return (
    <DetailSection
      label="Approved by"
      value={promotion.status_changed_by.label}
      icon='user'
    />
  );
};

export default StatusChangedBy;
