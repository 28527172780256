import { Model } from '@alephdata/followthemoney';
import { useSelector } from 'react-redux';
import { selectModel } from 'selectors';

function useModel() {
  const model: Model = useSelector((state) => {
    return selectModel(state);
  });

  return model;
}

export default useModel;
