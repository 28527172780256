import { CellParams } from 'classes/TableColumn';

type Props = {
  source: CellParams;
  schedule: CellParams;
  finished_at: CellParams;
  started_at: CellParams;
};

const params: Props = {
  source: {
    width: '45%',
  },
  schedule: {
    width: '20%',
  },
  started_at: {
    width: '17.5%',
  },
  finished_at: {
    width: '17.5%',
  },
};

export default params;
