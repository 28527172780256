import TableColumn from './PromotionColumn';

import { Promotion } from '../../types';

import { Icon } from '@blueprintjs/core';

class CreatedByColumn extends TableColumn {
  render(promotion: Promotion): React.ReactNode {
    const formattedDate = new Date(promotion.created_at)
      .toISOString()
      .split('T')[0];

    return (
      <>
        <Icon icon="time" className="promotion-manager-date-icon" />
        <span className="promotion-manager-item-date">
          {`Updated ${formattedDate}`}
        </span>
      </>
    );
  }
}

export default CreatedByColumn;
